import React from "react";
import { Button, Modal } from "semantic-ui-react";

const ConfirmationDialog = ({
    isOpen,
    title,
    message,
    onCancel,
    onSave
}) => {
    return (
        <Modal size="tiny" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                {message}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onCancel && onCancel()}>
                    Отмена
                </Button>
                <Button positive onClick={() => onSave && onSave()}>
                    Подтвердить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ConfirmationDialog;