import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Button, Container, Grid, Table, TableCell, TableRow } from "semantic-ui-react";
import { ADVENTURES_FORM_LINK } from "../../router/links";
import { apiService } from "../../utils/apiService";
import CreateNamedEntryDialog from "./components/createNamedEntryDialog";
import ConfirmationDialog from "../../modals/confirmationDialog";

const AdventuresListPage = () => {
    const [canEdit, setCanEdit] = useState(false);
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState({
        isOpen: false
    });

    const loadList = () => {
        setIsLoading(true);
        apiService.get('/v1/adventures/list')
            .then(result => {
                setIsLoading(false);
                setCanEdit(result && result.canEdit);
                setList((result && result.entries) || []);
            })
            .catch(() => setIsLoading(false));
    };

    useEffect(loadList, []);

    const onAddNewAdventure = (name) => {
        setIsNewDialogOpen(false);
        setIsLoading(true);
        apiService.post('/v1/adventures/create', { name, })
            .then(() => {
                loadList();
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const handleDelete = (id, name) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить приключение '${name}'?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        apiService.delete('/v1/adventures/' + id)
            .then(loadList)
            .catch(() => setIsLoading(false));
    };

    const renderItem = (item) => {
        return (
            <TableRow key={'adventure-' + item.id}>
                <TableCell>
                    <Link to={ADVENTURES_FORM_LINK.replace(':id', item.id)}>
                        {item.name}
                    </Link>
                </TableCell>
                <TableCell>
                    <Moment local format="DD.MM.YYYY hh:mm">
                        {item.createdAt}
                    </Moment>
                </TableCell>
                {canEdit && (<TableCell>
                    <Button 
                        icon="trash alternate"
                        onClick={() => handleDelete(item.id, item.name)}
                    />
                </TableCell>)}
            </TableRow>
        );
    };

    return (
        <Container>   
            <Grid>
                {canEdit && (<Grid.Row>
                    <Grid.Column>
                        <Button
                            disabled={isLoading}
                            onClick={() => setIsNewDialogOpen(true)}
                        >
                            Создать
                        </Button>
                    </Grid.Column>
                </Grid.Row>)}
                <Grid.Row>
                    <Grid.Column>
                        <Table basic='very'>
                            {list.map((item) => renderItem(item))}
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <CreateNamedEntryDialog
                isOpen={isNewDialogOpen}
                title='Создание нового приключения'
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewAdventure}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление приключения'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </Container>
    );
};

export default AdventuresListPage;