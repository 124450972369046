import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Dropdown, Form } from 'semantic-ui-react';

import { CHARACTER_FORM_LINK } from '../../router/links';
import { apiService } from '../../utils/apiService';

const CharacterNewPage = () => {
    var navigate = useNavigate();

    const [character, setCharacter] = useState({
        name: "",
        modelId: null
    });
    const [models, setModels] = useState([]);
    const [inProgress, setInProgress] = useState(false);

    const getData = () => {
        setInProgress(true);
        apiService.get('/v1/models/list')
            .then(result => {
                setInProgress(false);
                setModels((result && result.entries) || []);
            })
            .catch(() => {
                setInProgress(false);
            });
    }
    useEffect(getData, []);

    const save = () => {
        setInProgress(true);
        apiService.post('/v1/characters/create', character)
            .then(result => {
                setInProgress(false);
                if (result && result.id) {
                    navigate(CHARACTER_FORM_LINK.replace(':id', result.id));
                }
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    const updateField = (field, value) => {
        setCharacter({
            ...character,
            [field]: value
        });
    };

    return (
        <Container>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid required
                            label="Имя персонажа"
                            type="text" 
                            value={character.name}
                            onChange={(e, data) => updateField('name', data.value)} 
                            disabled={inProgress}
                        />
                    </Form.Field>

                    <Form.Field>
                        <Dropdown fluid required
                            label="Ролевая модель"
                            search
                            selection
                            value={character.modelId}
                            options={(models && models.map((v) => {return { value: v.id, text: v.name }})) || []}
                            onChange={(e, data) => updateField('modelId', data.value)}
                            disabled={inProgress}
                        />
                    </Form.Field>
                </Form.Group>
                
                <Button onClick={save} disabled={inProgress} floated="left">
                    Создать
                </Button>
            </Form>
        </Container>
    );
};

export default CharacterNewPage;