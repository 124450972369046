import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MarkdownEditor from "../../controls/markdownEditor";
import { apiService } from "../../utils/apiService";
import { Breadcrumb, BreadcrumbDivider, BreadcrumbSection, Container, Dropdown, Form, FormField, Icon } from "semantic-ui-react";
import { ADVENTURES_FORM_LINK, ADVENTURES_LIST_LINK } from "../../router/links";

const AdventuresPersonFormPage = () => {
    const { id } = useParams();
    
    const [form, setForm] = useState({});
    const [icons, setIcons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getData = () => {
        setIsLoading(true);
        apiService.get('/v1/adventurePersons/' + id)
            .then(result => {
                setIsLoading(false);
                setForm(result);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }
    useEffect(getData, [id]);

    const loadIcons = () => {
        apiService.get('/v1/icons/CharacterAvatar')
            .then(result => {
                setIcons((result && result.entries) || []);
            });
    };
    useEffect(loadIcons, []);

    const onBlur = () => {
        setIsLoading(true);
        apiService.put('/v1/adventurePersons/' + id, form)
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const iconOptions = icons.map((value) => {
        return {
            key: value.id,
            text: value.name,
            value: value.name,
            content: <Icon className={value.name} />
        };
    });

    const currentIcon = <Icon className={form.icon || 'no-person-icon'} />;

    return (
        <Container>
            <Breadcrumb size='large'>
                <BreadcrumbSection>
                    <Link to={ADVENTURES_LIST_LINK}>
                        Приключения
                    </Link>
                </BreadcrumbSection>
                <BreadcrumbDivider icon='right chevron' />
                <BreadcrumbSection>
                    <Link to={form.adventureId && ADVENTURES_FORM_LINK.replace(':id', form.adventureId)}>
                    {form.adventureName}
                    </Link>
                </BreadcrumbSection>
                <BreadcrumbDivider icon='right chevron' />
                <BreadcrumbSection>
                    {form.name}
                </BreadcrumbSection>
            </Breadcrumb>
            <Form>
                <div className="person-first-row">
                    <div className="person-icon">
                        <Dropdown
                            key={'AP-' + form.id + '-icon'}
                            disabled={isLoading}
                            options={iconOptions}
                            value={form.icon || ''}
                            onChange={(e, data) => setForm({ ...form, icon: data.value })}
                            onBlur={onBlur}
                            trigger={currentIcon}
                            icon={null}
                            scrolling
                        />
                    </div>
                    <div className="person-other-info">
                        <FormField>
                            <label>Имя</label>
                            <input
                                key={'AP-' + form.id + '-name'}
                                disabled={isLoading}
                                value={form.name || ''}
                                onChange={(e) => setForm({ ...form, name: e.target.value })}
                                onBlur={onBlur}
                            />
                        </FormField>
                        <FormField>
                            <label>Черты внешности</label>
                            <input
                                key={'AP-' + form.id + '-appearanceTraits'}
                                disabled={isLoading}
                                value={form.appearanceTraits || ''}
                                onChange={(e) => setForm({ ...form, appearanceTraits: e.target.value })}
                                onBlur={onBlur}
                            />
                        </FormField>
                        <FormField>
                            <label>Краткое описание</label>
                            <textarea
                                key={'AP-' + form.id + '-shortDescription'}
                                rows={2}
                                disabled={isLoading}
                                value={form.shortDescription || ''}
                                onChange={(e) => setForm({ ...form, shortDescription: e.target.value })}
                                onBlur={onBlur}
                            />
                        </FormField>
                    </div>
                </div>
                <FormField>
                    <MarkdownEditor
                        key={'AP-' + form.id + '-description'}
                        disabled={isLoading}
                        value={form.description || ''}
                        onChange={(value) => setForm({ ...form, description: value })}
                        onBlur={onBlur}
                    />
                </FormField>
            </Form>
        </Container>
    );
};

export default AdventuresPersonFormPage;