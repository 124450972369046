import React, { useState } from "react";
import { Form, FormField } from "semantic-ui-react";
import MarkdownEditor from "../../../controls/markdownEditor";
import { apiService } from "../../../utils/apiService";

const AdventureDescriptionTab = ({
    adventure,
    isLoading,
    onSave
}) => {
    const [form, setForm] = useState({
        name: adventure.name,
        description: adventure.description
    });

    const onBlur = () => {
        apiService.put('/v1/adventures/' + adventure.id, form)
            .then(() => onSave && onSave());
    };

    return (
        <Form>
            <FormField>
                <label>Название</label>
                <input
                    disabled={isLoading}
                    value={form.name || ''}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                    onBlur={onBlur}
                />
            </FormField>
            <FormField>
                <MarkdownEditor
                    disabled={isLoading}
                    value={form.description || ''}
                    onChange={(value) => setForm({ ...form, description: value })}
                    onBlur={onBlur}
                />
            </FormField>
        </Form>
    );
};

export default AdventureDescriptionTab;