import axios from 'axios';
import { parse, stringify } from 'qs';
import { toast } from 'react-toastify';

import { LOGIN_LINK } from '../router/links';

export const ACCESS_TOKEN = 'accessToken'; 

export const apiService = axios.create({
    baseURL: '/api',
    paramsSerializer: {
      encode: parse,
      serialize: (params) => stringify(params, {indices: false}),
    }
});

export const fileDownloader = axios.create({
    baseURL: '/api',
});

apiService.interceptors.response.use(
    resp => {
        return resp.data;
    },
    error => {
        const { data={}, status } = error.response;
        const { message = '' } = data;

        if (status === 401) {
            setAccessToken(null);
            if (!window.location.href.endsWith(LOGIN_LINK)) {
                window.location.href = LOGIN_LINK;
            }
        } else if (message) {
            toast.error(message, { autoClose: false });
        } else if (status === 500) {
            toast.error('Ошибка соединения', { autoClose: false });
        }

        return Promise.reject(error);
    }
);

export const setAccessToken = token => {
    if (token !== null) {
        localStorage.setItem(ACCESS_TOKEN, token);
        apiService.defaults.headers.common.Authorization = `Bearer ${token}`;
        fileDownloader.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        localStorage.removeItem(ACCESS_TOKEN);
        delete apiService.defaults.headers.common.Authorization;
        delete fileDownloader.defaults.headers.common.Authorization;
    }
};

export const hasToken = () => {
    return Boolean(localStorage.getItem(ACCESS_TOKEN));
};

setAccessToken(localStorage.getItem(ACCESS_TOKEN));