import React, { useEffect, useState } from "react";
import { apiService } from "../../../utils/apiService";
import { Button, Grid, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { ADVENTURES_PERSON_FORM_LINK } from "../../../router/links";
import CreateNamedEntryDialog from "../components/createNamedEntryDialog";
import ConfirmationDialog from "../../../modals/confirmationDialog";

const AdventurePersonsTab = ({
    adventureId,
    isDisabled
}) => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState({
        isOpen: false
    });

    const getData = () => {
        if (adventureId) {
            setIsLoading(true);
            apiService.get('/v1/adventurePersons/' + adventureId + '/list')
                .then(result => {
                    setIsLoading(false);
                    setList(result);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };
    useEffect(getData, [adventureId]);

    const onAddNewPerson = (name) => {
        setIsNewDialogOpen(false);
        if (adventureId) {
            setIsLoading(true);
            apiService.post('/v1/adventurePersons/' + adventureId + '/create', {adventureId, name})
                .then(() => {
                    getData();
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleDelete = (id, name) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить персонажа '${name}'?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        apiService.delete('/v1/adventurePersons/' + id)
            .then(() => {
                getData();
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const items = list.map((entry, ind) => (
        <Item key={'person-' + ind}>
            <Icon className={entry.icon || 'no-person-icon'} />
            <ItemContent>
                <ItemHeader>
                    <Link to={ADVENTURES_PERSON_FORM_LINK.replace(':id', entry.id)}>
                        {entry.name}
                    </Link>
                    <Icon 
                        className="remove-button"
                        name="trash alternate" 
                        onClick={() => handleDelete(entry.id, entry.name)}
                    />
                </ItemHeader>
                <ItemDescription>{entry.shortDescription}</ItemDescription>
            </ItemContent>
        </Item>
    ));

    return (
        <Grid>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <Button
                        disabled={isDisabled || isLoading}
                        onClick={() => setIsNewDialogOpen(true)}
                    >
                        Создать
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ItemGroup>{items}</ItemGroup>
                </Grid.Column>
            </Grid.Row>
            <CreateNamedEntryDialog
                isOpen={isNewDialogOpen}
                title='Создание нового персонажа'
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewPerson}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление персонажа'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </Grid>
    );
};

export default AdventurePersonsTab;