import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbDivider, BreadcrumbSection, Container, Tab } from 'semantic-ui-react';
import { apiService } from '../../utils/apiService';
import AdventureDescriptionTab from './tabs/adventureDescriptionTab';
import AdventureChaptersTab from './tabs/adventureChaptersTab';
import AdventurePersonsTab from './tabs/adventurePersonsTab';
import { ADVENTURES_LIST_LINK } from '../../router/links';

const AdventuresFormPage = () => {
    const { id } = useParams();
    
    const [adventure, setAdventure] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const getData = () => {
        setIsLoading(true);
        apiService.get('/v1/adventures/' + id)
            .then(result => {
                setIsLoading(false);
                setAdventure(result);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }
    useEffect(getData, [id]);

    const panes = [
        {
            menuItem: 'Описание',
            render: () => <AdventureDescriptionTab 
                key={'description-' + (adventure.id || '')}
                adventure={adventure} 
                isLoading={isLoading} 
                onSave={getData}
            />
        },
        {
            menuItem: 'Главы',
            render: () => <AdventureChaptersTab 
                key={'chapter-' + (adventure.id || '')}
                adventureId={adventure.id} 
                isDisabled={isLoading} 
            />
        },
        {
            menuItem: 'Персонажи',
            render: () => <AdventurePersonsTab 
                key={'person-' + (adventure.id || '')}
                adventureId={adventure.id} 
                isDisabled={isLoading} 
            />
        },
    ];

    return (
        <Container>
            <Breadcrumb size='large'>
                <BreadcrumbSection>
                    <Link to={ADVENTURES_LIST_LINK}>
                        Приключения
                    </Link>
                </BreadcrumbSection>
                <BreadcrumbDivider icon='right chevron' />
                <BreadcrumbSection>
                    {adventure && adventure.name}
                </BreadcrumbSection>
            </Breadcrumb>
            <Tab
                menu={{
                    secondary: true,
                    pointing: true
                }}
                panes={panes}
            />
        </Container>
    );
};

export default AdventuresFormPage;