import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Button, Icon, Image, Menu, Dropdown } from "semantic-ui-react";

import { apiService } from '../../utils/apiService';

import {
    DASHBOARD_LINK,
    CHARACTER_FORM_LINK,
    CHARACTER_NEW_LINK,
    GROUPS_LIST_LINK,
    GROUPS_BASE_LINK,
    PROFILE_LINK,
    ROLE_MODELS_BASE_LINK,
    ROLE_MODELS_LIST_LINK,
    ADVENTURES_BASE_LINK,
    ADVENTURES_LIST_LINK
} from '../../router/links';

const AppHeader = ({ history, user, logout }) => {
    const fixedMenuStyle = {
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    }

    let location = useLocation();

    const [characters, setCharacters] = useState([]);
    const [activeItem, setActiveItem] = useState(location.pathname);

    useEffect(() => {
        apiService.get('/v1/characters/list')
            .then(result => setCharacters(result));
    }, []);

    useEffect(() => {
        setActiveItem(location.pathname);
    }, [location.pathname]);

    const charactersItems = characters && characters.map((c) => {
        let link = CHARACTER_FORM_LINK.replace(':id', c.id);
        return <Dropdown.Item as={Link} to={link} key={c.id}>{c.name}</Dropdown.Item>
    });

    return !(user && user.email) ? null : (
        <Menu borderless style={fixedMenuStyle}>
            <Menu.Item header>
                <Image size='mini' src='/favicon-32x32.png' style={{ paddingRight: '1em' }} />
            </Menu.Item>
            <Menu.Item as={Link} to={DASHBOARD_LINK} active={activeItem.includes(DASHBOARD_LINK)}>Главная</Menu.Item>
            {user && user.canViewCharacters && (
                <Dropdown item text='Персонажи'>
                    <Dropdown.Menu>
                        {charactersItems}
                        {charactersItems && charactersItems.length > 0 ? <Dropdown.Divider /> : null}
                        <Dropdown.Item as={Link} to={CHARACTER_NEW_LINK}>Создать нового</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {user && user.canViewGroups && (
                <Menu.Item as={Link} to={GROUPS_LIST_LINK} active={activeItem.includes(GROUPS_BASE_LINK)}>Группы</Menu.Item>
            )}
            {user && user.canViewRoleModels && (
                <Menu.Item as={Link} to={ROLE_MODELS_LIST_LINK} active={activeItem.includes(ROLE_MODELS_BASE_LINK)}>Ролевые модели</Menu.Item>
            )}
            {user && user.canViewAdventures && (
                <Menu.Item as={Link} to={ADVENTURES_LIST_LINK} active={activeItem.includes(ADVENTURES_BASE_LINK)}>Приключения</Menu.Item>
            )}
            <Menu.Item position="right">
                <Link to={PROFILE_LINK}><b>{user.email}</b></Link>
                <Button icon compact style={{ marginLeft: '1em' }} onClick={logout}>
                    <Icon name="log out" />
                </Button>
            </Menu.Item>
        </Menu>
    );
};

export default AppHeader;