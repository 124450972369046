import React from "react";
import { 
    BlockTypeSelect,
    BoldItalicUnderlineToggles, 
    CodeToggle, 
    ListsToggle, 
    MDXEditor, 
    UndoRedo, 
    headingsPlugin, 
    listsPlugin, 
    quotePlugin, 
    toolbarPlugin 
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

const MarkdownEditor = ({
    disabled,
    onBlur,
    onChange,
    value,
}) => {
    return (
        <MDXEditor 
            style={{maxHeight: 100}}
            disabled={disabled}
            markdown={value || ''}
            plugins={[
                toolbarPlugin({
                    toolbarContents: () => (
                    <>
                        {' '}
                        <UndoRedo />
                        <BoldItalicUnderlineToggles />
                        <BlockTypeSelect />
                        <ListsToggle />
                        <CodeToggle />
                    </>
                    )
                }),
                headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
                listsPlugin(),
                quotePlugin(),
            ]}
            onChange={(markdown) => onChange(markdown)}
            onBlur={onBlur}
        />
    );
};

export default MarkdownEditor;