import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Form } from 'semantic-ui-react';

import FormInput from '../../controls/input';
import { apiService } from '../../utils/apiService';

const CharacterFormPage = () => {
    const { id } = useParams();
    
    const [character, setCharacter] = useState({});
    const [inProgress, setInProgress] = useState(false);

    const getData = () => {
        setInProgress(true);
        apiService.get('/v1/characters/' + id)
            .then(result => {
                setInProgress(false);
                setCharacter(result);
            })
            .catch(() => {
                setInProgress(false);
            });
    }
    useEffect(getData, [id]);

    const updateField = (fieldName, value) => {
        const request = {
            fieldName,
            value : '' + value
        };
        
        setInProgress(true);
        apiService.post('/v1/characters/' + id + '/field', request)
            .then(result => {
                setInProgress(false);
                setCharacter(result);
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    return (
        <Container>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Имя персонажа</label>
                        <FormInput fluid
                            type="text"
                            id="Name"
                            value={character.name}
                            onChange={(value) => updateField('Name', value)}
                            disabled={inProgress}
                        />
                    </Form.Field>
                </Form.Group>
                <br/>
            </Form>
        </Container>
    );
};

export default CharacterFormPage;