import React from "react";
import { Button, Form, Input, Segment } from "semantic-ui-react";

const FieldForm = ({ form, onChange, onDelete }) => {
    const handleFieldChange = (name, value) => {
        const newForm = {
            ...form,
            [name]: value
        };
        onChange && onChange(newForm);
    };

    return (
        <>
            <Segment>
                <Form>
                    <Form.Field inline>
                        <label>Название</label>
                        <Input 
                            value={(form && form.name) || ''}
                            onChange={(e, { value }) => handleFieldChange('name', value)}
                        />
                    </Form.Field>
                    <Button color="red" onClick={() => onDelete && onDelete()}>
                        Удалить поле
                    </Button>
                </Form>
            </Segment>
        </>
    );
};

export default FieldForm;