import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Dimmer, Loader } from 'semantic-ui-react';

import App from './containers/app'

import 'semantic-ui-css/semantic.min.css';

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
    <Suspense
        fallback={
            <Dimmer active inverted>
                <Loader size='huge'>Loading</Loader>
            </Dimmer>
        }
    >
        <App/>
    </Suspense>
);