export const LOGIN_LINK = '/login';
export const DASHBOARD_LINK = '/dashboard';
export const ADVENTURES_BASE_LINK = '/adventures/';
export const ADVENTURES_FORM_LINK = '/adventures/:id';
export const ADVENTURES_PERSON_FORM_LINK = '/adventures/persons/:id';
export const ADVENTURES_CHAPTER_FORM_LINK = '/adventures/chapters/:id';
export const ADVENTURES_LIST_LINK = '/adventures/list';
export const CHARACTER_FORM_LINK = '/character/form/:id';
export const CHARACTER_NEW_LINK = '/character/new';
export const GROUPS_BASE_LINK = '/groups/';
export const GROUPS_FORM_LINK = '/groups/:id';
export const GROUPS_LIST_LINK = '/groups/list';
export const ROLE_MODELS_BASE_LINK = '/models/';
export const ROLE_MODELS_FORM_LINK = '/models/:id';
export const ROLE_MODELS_NEW_LINK = '/models/new';
export const ROLE_MODELS_LIST_LINK = '/models/list';
export const PROFILE_LINK = '/profile';
export const REGISTER_LINK = '/register';
export const PASSWORD_BASE_LINK = '/password/';
export const RESTORE_PASSWORD_LINK = '/password/restore';
export const SET_PASSWORD_LINK = '/password/set/:id/:token';