import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Dimmer, Loader } from "semantic-ui-react";

import MainRoute from '../router';
import AppHeader from "./header";
import { LOGIN_LINK, PASSWORD_BASE_LINK, REGISTER_LINK } from "../router/links";
import { hasToken, apiService, setAccessToken } from "../utils/apiService";

const AppAuthWrapper = () => {
    const [user, setUser] = useState({});

    const navigate = useNavigate();

    const isNoAuthPage = window.location.href.endsWith(LOGIN_LINK)
        || window.location.href.endsWith(REGISTER_LINK)
        || window.location.href.indexOf(PASSWORD_BASE_LINK) >= 0;

    const logout = () => {
        setUser({});
        setAccessToken(null);
        navigate(LOGIN_LINK);
    };

    const refreshToken = () => {
        if (hasToken()) {
            apiService.get('/v1/users/refresh')
                .then(({ token }) => {
                    setAccessToken(token);
                })
                .catch(logout);
        }
    };

    useEffect(
        () => {
            if (!isNoAuthPage) {
                if (!hasToken()) {
                    logout();
                } else if (!user || !user.email) {
                    apiService.get('/v1/users/info')
                        .then(result => setUser(result))
                        .catch(logout);
                }
            }
        }
    );

    useEffect(
        () => {
            refreshToken();
            const timer = setInterval(refreshToken, 600000);
            return () => clearInterval(timer);
        }
    );
    
    return (
        <>
            {isNoAuthPage ? (
                <MainRoute user={user} />
            ) : (user && user.email) ? (
                <>
                    <AppHeader user={user} logout={logout} />
                    <MainRoute user={user} />
                </>
            ) : (
                <Dimmer active inverted>
                    <Loader size="huge">Loading</Loader>
                </Dimmer>
            )}
        </>
    );
};

export default AppAuthWrapper;