import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';

import { REGISTER_LINK, RESTORE_PASSWORD_LINK } from '../../router/links';
import { apiService, setAccessToken } from '../../utils/apiService';

const LoginPage = ({ history, user }) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [inProgress, setInProgress] = useState(false);

    useEffect(
        () => {
            if (user && user.email) {
                navigate("/");
            }
        }
    );

    const onLogin = () => {
        setInProgress(true);
        apiService.post('/v1/users/login', { email, password })
            .then(({ token }) => {
                setInProgress(false);
                setAccessToken(token);
                navigate("/");
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    return (
        <Grid textAlign='center' verticalAlign='middle' style={{ height: '100vh' }}>
            <Grid.Column style={{ maxWidth: 400 }}>
                <Header>
                    <Image src='/android-chrome-192x192.png' size='massive' />
                </Header>
                <Form size='large'>
                    <Segment stacked>
                        <Form.Input 
                            fluid
                            icon='user'
                            iconPosition='left'
                            placeholder='Email'
                            type='email'
                            disabled={inProgress}
                            onChange={(e, { value }) => setEmail(value)}
                        />
                        <Form.Input 
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Пароль'
                            type='password'
                            disabled={inProgress}
                            onChange={(e, { value }) => setPassword(value)}
                        />
                        <Button fluid size='large' onClick={onLogin} disabled={inProgress}>
                            Войти
                        </Button>
                    </Segment>
                </Form>
                <Message>
                    <a href={REGISTER_LINK}>Зарегистрироваться</a> / <a href={RESTORE_PASSWORD_LINK}>Восстановить пароль</a>
                </Message>
            </Grid.Column>
        </Grid>
    );
};

export default LoginPage;