import React, { useState } from "react";
import { Accordion, Button, Form, Icon, Input } from "semantic-ui-react";
import FieldForm from "./fieldForm";

const FieldGroupForm = ({ form, onChange, onDelete }) => {
    const fieldTypeField = 'Field';
    const fieldTypeAttribute = 'Attribute';

    const [showFieldsBlock, setShowFieldsBlock] = useState(false);
    const [showAttributesBlock, setShowAttributesBlock] = useState(false);

    const handleFieldChange = (name, value) => {
        const newForm = {
            ...form,
            [name]: value
        };
        onChange && onChange(newForm);
    };

    const handleAddField = (fieldType) => {
        const newField = {
            name: '',
            fieldType: { id: fieldType }
        };
        const newForm = {
            ...form,
            fields: [...form.fields].concat([ newField ])
        };
        onChange && onChange(newForm);
    };

    const handleFieldEntryChange = (newField, index) => {
        if (form && form.fields) {
            const newForm = {
                ...form,
                fields: form.fields.slice(0, index)
                            .concat([newField])
                            .concat(form.fields.slice(index + 1))
            };
            onChange && onChange(newForm);
        }
    };

    const handleFieldEntryDelete = (index) => {
        if (form && form.fields) {
            const newForm = {
                ...form,
                fields: form.fields.slice(0, index)
                            .concat(form.fields.slice(index + 1))
            };
            onChange && onChange(newForm);
        }
    };

    return (
        <>
            <Form>
                <Form.Field inline>
                    <label>Название группы</label>
                    <Input 
                        value={(form && form.name) || ''}
                        onChange={(e, { value }) => handleFieldChange('name', value)}
                    />
                </Form.Field>
                <Form.Checkbox
                    inline
                    label="Является коллекцией"
                    value={form && form.isCollection}
                    onChange={(e, { value }) => handleFieldChange('isCollection', value)}
                />
                <Button color="red" onClick={() => onDelete && onDelete()}>
                    Удалить группу
                </Button>
            </Form>
            <Accordion styled fluid exclusive={false}>
                <Accordion.Title
                    active={showFieldsBlock}
                    index={0}
                    onClick={() => setShowFieldsBlock(!showFieldsBlock)}
                >
                    <Icon name="dropdown" />
                    Поля группы
                </Accordion.Title>
                <Accordion.Content active={showFieldsBlock}>
                    {form && form.fields && form.fields.map((field, index) =>
                        field.fieldType && field.fieldType.id === fieldTypeField
                            ? <FieldForm 
                                form={field} 
                                onChange={(newField) => handleFieldEntryChange(newField, index)}
                                onDelete={() => handleFieldEntryDelete(index)} 
                              />
                            : null
                    )}
                    <Button positive onClick={() => handleAddField(fieldTypeField)}>
                        Добавить поле
                    </Button>
                </Accordion.Content>
                <Accordion.Title
                    active={showAttributesBlock}
                    index={1}
                    onClick={() => setShowAttributesBlock(!showAttributesBlock)}
                >
                    <Icon name="dropdown" />
                    Атрибуты полей
                </Accordion.Title>
                <Accordion.Content active={showAttributesBlock}>
                    {form && form.fields && form.fields.map((field, index) =>
                        field.fieldType && field.fieldType.id === fieldTypeAttribute
                            ? <FieldForm 
                                form={field} 
                                onChange={(newField) => handleFieldEntryChange(newField, index)}
                                onDelete={() => handleFieldEntryDelete(index)} 
                            />
                            : null
                    )}
                    <Button positive onClick={() => handleAddField(fieldTypeAttribute)}>
                        Добавить атрибут
                    </Button>
                </Accordion.Content>
            </Accordion>
        </>
    );
};

export default FieldGroupForm;