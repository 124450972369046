import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { apiService } from "../../utils/apiService";
import { Breadcrumb, BreadcrumbDivider, BreadcrumbSection, Container, Form, FormField } from "semantic-ui-react";
import { ADVENTURES_FORM_LINK, ADVENTURES_LIST_LINK } from "../../router/links";
import MarkdownEditor from "../../controls/markdownEditor";

const AdventuresChapterFormPage = () => {
    const { id } = useParams();
    
    const [form, setForm] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const getData = () => {
        setIsLoading(true);
        apiService.get('/v1/adventureChapters/' + id)
            .then(result => {
                setIsLoading(false);
                setForm(result);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }
    useEffect(getData, [id]);

    const onBlur = () => {
        setIsLoading(true);
        apiService.put('/v1/adventureChapters/' + id, form)
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    return (
        <Container>
            <Breadcrumb size='large'>
                <BreadcrumbSection>
                    <Link to={ADVENTURES_LIST_LINK}>
                        Приключения
                    </Link>
                </BreadcrumbSection>
                <BreadcrumbDivider icon='right chevron' />
                <BreadcrumbSection>
                    <Link to={form.adventureId && ADVENTURES_FORM_LINK.replace(':id', form.adventureId)}>
                    {form.adventureName}
                    </Link>
                </BreadcrumbSection>
                <BreadcrumbDivider icon='right chevron' />
                <BreadcrumbSection>
                    {form.name}
                </BreadcrumbSection>
            </Breadcrumb>
            <Form>
                <FormField>
                    <label>Название</label>
                    <input
                        key={'AC-' + form.id + '-name'}
                        disabled={isLoading}
                        value={form.name || ''}
                        onChange={(e) => setForm({ ...form, name: e.target.value })}
                        onBlur={onBlur}
                    />
                </FormField>
                <FormField>
                    <label>Краткое описание</label>
                    <textarea
                        key={'AC-' + form.id + '-shortDescription'}
                        rows={2}
                        disabled={isLoading}
                        value={form.shortDescription || ''}
                        onChange={(e) => setForm({ ...form, shortDescription: e.target.value })}
                        onBlur={onBlur}
                    />
                </FormField>
                <FormField>
                    <MarkdownEditor
                        key={'AC-' + form.id + '-description'}
                        disabled={isLoading}
                        value={form.description || ''}
                        onChange={(value) => setForm({ ...form, description: value })}
                        onBlur={onBlur}
                    />
                </FormField>
            </Form>
        </Container>
    );
};

export default AdventuresChapterFormPage;