import React, { useState } from 'react';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';

import { LOGIN_LINK, RESTORE_PASSWORD_LINK } from '../../router/links';
import { apiService } from '../../utils/apiService';

const RegisterPage = () => {
    let [email, setEmail] = useState('');
    let [inProgress, setInProgress] = useState(false);
    let [isComplete, setIsComplete] = useState(false);

    const onRegister = () => {
        setInProgress(true);
        apiService.post('/v1/users/register', { email })
            .then(() => {
                setInProgress(false);
                setIsComplete(true);
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    return (
        <Grid textAlign='center' verticalAlign='middle' style={{ height: '100vh' }}>
            <Grid.Column style={{ maxWidth: 400 }}>
                <Header>
                    <Image src='/android-chrome-192x192.png' size='massive' />
                </Header>
                {isComplete ? (
                    <Message>
                        На вашу почту <b>{email}</b> отправлено письмо с ссылкой для продолжения регистрации
                    </Message>
                ) : (
                    <Form size='large'>
                        <Segment stacked>
                            <Form.Input 
                                fluid
                                icon='user'
                                iconPosition='left'
                                placeholder='Email'
                                type='email'
                                disabled={inProgress}
                                onChange={(e, { value }) => setEmail(value)}
                            />
                            <Button fluid size='large' onClick={onRegister} disabled={inProgress}>
                                Зарегистрироваться
                            </Button>
                        </Segment>
                    </Form>
                )}
                <Message>
                    <a href={LOGIN_LINK}>Войти в систему</a> / <a href={RESTORE_PASSWORD_LINK}>Восстановить пароль</a>
                </Message>
            </Grid.Column>
        </Grid>
    );
};

export default RegisterPage;